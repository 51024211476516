import Vue from "vue"
import DatePicker from "vue2-datepicker"
import VueQRCodeComponent from 'vue-qrcode-component'
import Approve from "./widget/widget-approve"
import CreateWeightList from './widget/widget-create'
import Loading from "@/components/widgets/Loading.vue"
import SortedTablePlugin from "vue-sorted-table"
import UploadExcel from './widget/widget-uploadfile'
import DefinePrice from './widget/widget-price'
import 'jspdf-autotable'
import jsPDF from 'jspdf'
import Paginate from "@/components/widgets/pagination.vue";
import vSelect from 'vue-select'

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    Loading,
    DatePicker,
    Approve,
    'qr-code': VueQRCodeComponent,
    UploadExcel,
    DefinePrice,
    CreateWeightList,
    Paginate,
    vSelect
  },
  data: function () {
    return {
      host: Vue.prototype.$host,
      fileUpload: false,
      definePrice: false,
      busy: false,
      createId: "",
      selectedId: "",
      role: "",
      branch: "",
      loading: false,
      approved: false,
      openedCreate: false,
      openedBarcode: false,
      status: null,
      totalItem: 0,
      apiStatus: 'ไม่พบข้อมูล',
      keyword_lot: "",
      keyword_color: null,
      keyword_thick: "",
      keyword_brand: null,
      keyword_code: "",
      keyword_row: "",
      keyword_warehouse: "",
      keyword_status: null,
      created: {
        start: null,
        end: null,
      },
      duedate: {
        start: null,
        end: null,
      },
      delivery: {
        start: null,
        end: null,
      },
      mode: 'create',
      sortBy: 'name',
      perPage: 8,
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      keyword: '',
      fields: [
        {
          key: "lot_no",
          label: "LOT No.",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "code",
          label: "รหัสม้วน",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "brand",
          label: "ยี่ห้อ.",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "invoice_date",
          label: "Invoice date",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "coil",
          label: "COIL NO.",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "size",
          label: "SIZE",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "color",
          label: "COLOR",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "net_weight",
          label: "NET WEIGHT",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "kg_m",
          label: "Kg/m",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "length",
          label: "LENGTH",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "status",
          label: "สถานะ",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "can_pick",
          label: "สาขาสั่งได้",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "locked",
          label: "ติดจอง",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "warehouse",
          label: "คลัง",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "row",
          label: "แถว",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "price",
          label: "ราคาต่อกิโล/Kg",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
        },
        {
          key: "sale_price",
          label: "ราคาขาย",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
        },
        {
          key: "at_branch.name",
          label: "สาขา",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
        },
        {
          key: "note",
          label: "หมายเหตุ",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
        },
        {
          key: "action",
          label: "#",
          thClass: "text-xs-1/2 font-weight-bold",
          class: "text-right text-xs-1/2",
        },
      ],
      items: [],
      barcodeItems: [],
      base64Images: [],
    }
  },
  computed: {
    rows() {
      return this.items.length;
    }
  },
  async mounted() {
    const plugin = document.createElement("script")
    plugin.setAttribute(
    "src",
    "https://cdn.jsdelivr.net/gh/davidshimjs/qrcodejs/qrcode.min.js")
    plugin.async = true;
    document.head.appendChild(plugin)

    // this.onInitData(1)
    await this.getRole()
    await this.loadBrandOptions()
    await this.loadSizeOptions()
    await this.loadColorOptions()
  },
  methods: {
    async getRole(){
      const res = await this.$store.dispatch('Auth/getRole', {}, { root: true })
      this.role = res?.data.role
      this.branch = res?.data.branch_id
    },
    async onInitData(currentPage) {
      try {
        this.busy = true
        // this.items = []
        this.apiStatus = 'กำลังค้นหาข้อมูล..'

        const page = currentPage ? currentPage : 1
        const result = await this.$axios.get(
          this.$host + "/api/v1/china-order?page=" + page
          + "&keyword=" + this.keyword
          + "&keyword_lot=" + this.keyword_lot
          + "&keyword_color=" + (this.keyword_color ? this.keyword_color : '')
          + "&keyword_thick=" + this.keyword_thick
          + "&keyword_brand=" + (this.keyword_brand ? this.keyword_brand : "")
          + "&keyword_code=" + this.keyword_code
          + "&keyword_row=" + this.keyword_row
          + "&keyword_status=" + (this.keyword_status ? this.keyword_status : "")
          + "&keyword_warehouse=" + this.keyword_warehouse,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            }
          }
        )

        this.apiStatus = 'ไม่พบข้อมูล'
        this.currentData = result.data
        this.items = result.data.data
        this.totalItem = result.data.total
        this.busy = false
        this.barcodeItems = this.items.filter(item => {
          return item.uuid && item.uuid !== ''
        })
      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }      
    },
    drawerClose(data) {
      if (data.status) {
        this.approved = false
        this.onInitData(1)
      }
    },
    onCreateClose(data) {
      if (data.status) {
        this.openedCreate = false
        this.onInitData(1)
      }
    },
    onUploadFile() {
      this.fileUpload = !this.fileUpload
    },
    onDefinePrice() {
      this.definePrice = !this.definePrice
    },
    drawerExcelClose(data) {
      if (data.status) {
        this.fileUpload = false
        this.onInitData(1)
      }
    },
    drawerDefinePriceClose(data) {
      if (data.status) {
        this.definePrice = false
        this.onInitData(1)
      }
    },
    drawerBarcodeClose(data) {
      if (data.status) {
        this.openedBarcode = false
      }
    },
    onCreateBarcode() {
      return new Promise(async (resolve, reject) => {
        await this.onCreateBarcodeTable()
        const doc = new jsPDF();
        doc.autoTable({
          html: '#mytable',
          bodyStyles: {minCellHeight: 50},
          didDrawCell: function(data) {
            if (data.column.index === 1 && data.cell.section === 'body') {
              var td = data.cell.raw;
              var img = td.getElementsByTagName('img')[0];

              const image = new Image()
              image.src = img.src
              image.onload = () => {
                // await for the image to be fully loaded
                // var dim = data.cell.height - data.cell.padding('vertical');
                var textPos = data.cell;
                doc.addImage(img.src, 'PNG', textPos.x-20,  textPos.y, 40, 40);
              }
            }
          }
        })
        resolve(doc)
      })
    },
    onCreateBarcodeTable() {
      return new Promise((resolve, reject) => {
        const qrList = this.items.filter(item => {
          return item.uuid && item.uuid !== ''
        })
        qrList.forEach(qr => {
          // eslint-disable-next-line no-undef
          const qrcode = new QRCode("qr_code", {
              text: `${this.$host}/stock/${qr.uuid}`,
              width: 128,
              height: 128,
              colorDark : "#000000",
              colorLight : "#ffffff",
              // eslint-disable-next-line no-undef
              correctLevel : QRCode.CorrectLevel.L
          });
          this.base64Images.push(qrcode._oDrawing._elCanvas.toDataURL("image/png"))
        })
        resolve(this.base64Images)
      })
    },
    async generateBarcode() {
      const doc = await this.onCreateBarcode()
      setTimeout(() => {
        window.open(URL.createObjectURL(doc.output("blob")))
      })
    },
    generateQRcode() {
      this.loading = true
      setTimeout(async () => {
        const result = await this.$axios.get(
          this.$host + "/api/v1/downloadQRcodePDF?keyword=" + this.keyword
          + "&keyword_lot=" + this.keyword_lot
          + "&keyword_color=" + (this.keyword_color ? this.keyword_color : '')
          + "&keyword_thick=" + this.keyword_thick
          + "&keyword_brand=" + (this.keyword_brand ? this.keyword_brand : "")
          + "&keyword_code=" + this.keyword_code
          + "&keyword_row=" + this.keyword_row
          + "&keyword_status=" + (this.keyword_status ? this.keyword_status : "")
          + "&keyword_warehouse=" + this.keyword_warehouse,

          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            responseType: "blob"
          }
        )
        const blob = new Blob([result.data]);
        const objectUrl = URL.createObjectURL(blob)
        const link = document.createElement("a");
        link.href = objectUrl;
        link.setAttribute(
          "download",
          `QR Stock-${new Date().getTime()}.pdf`
        );
        document.body.appendChild(link);
        link.click();
  
        URL.revokeObjectURL(objectUrl)
        this.loading = false
      }, 1000)
    },
    oncreate() {
      this.mode = 'create'
      this.openedCreate = !this.openedCreate
    },
    onupdate(id) {
      this.createId = id
      this.mode = 'update'
      this.openedCreate = !this.openedCreate
    },
    ondeleted(id) {
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
          '<div class="d-flex flex-column">'+
          '<span class="font-bold text-base py-2.5 text-left">คุณต้องการจะลบข้อมูลนี้หรือไม่?</span>'+
          '<span class="text-base text-left text-gray-500">หากคุณยืนยันแล้ว ข้อมูลจะไม่สามารถกู้คืนกลับมาได้?</span>'+
          '</div>'+
        '</div>',
          allowOutsideClick: false,
          focusConfirm: false,
          customClass: {
            popup: 'swal2-custom-rounded',
            closeButton: 'text-3xl swal2-custom-close',
            actions: 'justify-content-end',
            contant: 'd-flex flex-row justify-content-around px-2',
            title: 'd-none',
            confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
          },
          cancelButtonColor: '#dc4a38',
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: "ตกลง",
          showCancelButton: true,
          showCloseButton: true,
      }).then(async confirm => {
        if(confirm.value){
          this.loading = true
          this.realDelete(id)
        }
      })
    },
    async realDelete(id) {
      try {
        await this.$axios.delete(this.$host + "/api/v1/china-order/" + id)

        this.loading = false
        this.$swal({
          width: "29rem",
          padding: '1.825em',
          html: '<div class="d-flex flex-row align-items-center">' +
            '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
            '<div class="d-flex flex-column">'+
            '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
            '<span class="text-base text-left text-gray-500">ลบข้อมูลเรียบร้อยแล้ว</span>'+
            '</div>'+
          '</div>',
            allowOutsideClick: false,
            focusConfirm: false,
            customClass: {
              popup: 'swal2-custom-rounded',
              closeButton: 'text-3xl swal2-custom-close',
              actions: 'justify-content-end',
              contant: 'd-flex flex-row justify-content-around px-2',
              title: 'd-none',
              confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
              cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
            },
            cancelButtonColor: '#dc4a38',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: "ตกลง",
            showCancelButton: false,
            showCloseButton: true,
        }).then(async confirm => {
          if(confirm.value){
            this.onInitData(1)
          }
        })
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }      
    },
    async Export() {
      try {
        this.loading = true
        await this.$axios.post(`${Vue.prototype.$host}/api/v1/exportChinaOrderExcel`, {}, {
          responseType: 'blob'
        }).then((response) => {
          const url = URL.createObjectURL(new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'rolls.xlsx')
          document.body.appendChild(link)
          link.click()
          this.loading = false
        })
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async loadBrandOptions() {
      try {
        const result = await this.$axios.get(
          `${this.$host}/api/v1/master/brand`
        )
        if (result) {
          const starter = [{ uuid: null, name: "-- กรุณาเลือกยี่ห้อ --" }]
          this.brandOptions = starter.concat(result.data)

        }
      } catch (error) {
        this.busy = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async loadSizeOptions() {
      try {
        const result = await this.$axios.get(
          `${this.$host}/api/v1/master/size`
        )
        if (result) {
          const starter = [{ uuid: '', name: "-- กรุณาระบุขนาด --" }]
          this.sizeOptions = starter.concat(result.data)

        }
      } catch (error) {
        this.busy = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async loadColorOptions() {
      try {
        const result = await this.$axios.get(
          `${this.$host}/api/v1/master/color`
        )
        if (result) {
          const starter = [{ uuid: null, name: "-- กรุณาระบุสี --" }]
          this.colorOptions = starter.concat(result.data)

        }
      } catch (error) {
        this.busy = false
        this.onExceptionHandler(error.response.data.message);
      }
    }
  }
}